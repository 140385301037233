import Link from "components/common/Link";
import TextLink from "components/common/TextLink";
import { css, cx } from "linaria";
import { kebabCase } from "lodash";
import React from "react";

import { getCategoryName } from "../../utils";
import { useSetAtom } from "jotai";
import { menuOpenAtom } from "./state";

const postClass = css`
  a {
    display: block;
    text-decoration: none;
  }
`;

const postInnerClass = css`
  position: relative;
`;

const imageContainerClass = css`
  width: 100%;
`;

const featuredImgClass = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: all 500ms ease;

  .${postClass}:hover & {
    transform: scale(1.03);
  }
`;

const imgClass = css`
  width: 100%;
  height: 201px;
  object-fit: cover;
  border-radius: 8px;
  transition: all 500ms ease;

  .${postClass}:hover & {
    transform: scale(1.03);
  }
`;

const postContentClass = css`
  display: flex;
  flex-direction: column;
  padding: 25px 0px 16px 0px;
  overflow: hidden;
  max-width: 798px;

  h2 {
    color: rgba(43, 46, 48, 0.99);
  }
`;

const featuredPostContentClass = cx(
  postContentClass,
  css`
    @media (max-width: 1050px) {
      height: unset;
    }
  `
);

const excerptContainerClass = css`
  position: relative;
  margin-top: 16px;
  flex: 1 2;
  min-width: 0;
  overflow: hidden;
  height: 50px;
`;

const excerptClass = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #9a9a9a;
`;

const postTagsClass = css`
  flex: 0 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 12px;

  a {
    font-size: 12px;
  }

  p {
    font-size: 12px;
    color: #9a9a9a;
    line-height: 1.25;
  }
`;

const postTitleClass = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const featuredPostClass = css`
  a {
    display: block;
    text-decoration: none;
  }
`;

const featuredPostInnerClass = cx(
  postInnerClass,
  css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `
);

const featuredImageContainerClass = cx(
  imageContainerClass,
  css`
    width: 100%;
    max-width: 798px;

    img {
      transition: all 500ms ease;

      .${featuredPostClass}:hover & {
        transform: scale(1.03);
      }
    }
  `
);

export default function BlogPostCard({ post, featured = false }) {
  const setMenuOpen = useSetAtom(menuOpenAtom);
  const category = post.frontmatter.category;
  const splitCategory = category.split("-");
  const altText =
    post.frontmatter.featuredImages?.featuredImageAltText ??
    post.frontmatter.title;

  return (
    <div
      className={featured ? featuredPostClass : postClass}
      onClick={() => setMenuOpen(false)}
    >
      <div className={featured ? featuredPostInnerClass : postInnerClass}>
        <Link
          to={post.fields.slug}
          className={
            featured ? featuredImageContainerClass : imageContainerClass
          }
        >
          <img
            className={featured ? featuredImgClass : imgClass}
            src={
              post.frontmatter.featuredImages?.featuredImageThumbnail ??
              post.frontmatter.thumbnail
            }
            alt={altText}
          />
        </Link>
        <div className={featured ? featuredPostContentClass : postContentClass}>
          <div className={postTagsClass}>
            {splitCategory.length > 1 ? (
              <TextLink to={`/blog/industry-solutions/`}>
                {splitCategory[1].toUpperCase()}
              </TextLink>
            ) : (
              <TextLink to={`/blog/${kebabCase(getCategoryName(category))}/`}>
                {category.toUpperCase()}
              </TextLink>
            )}
            <p>{post.frontmatter.date}</p>
          </div>
          <Link to={post.fields.slug}>
            <h2 className={postTitleClass}>{post.frontmatter.title}</h2>
          </Link>
          {featured && (
            <Link to={post.fields.slug} className={excerptContainerClass}>
              <p className={excerptClass}>{post.frontmatter.description}</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
