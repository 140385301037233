import { useEffect, useState } from "react";

export const useIsElementOverflowing = ref => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (typeof window === "undefined") return;
    const overflowing = ref.current.scrollWidth > window.innerWidth;
    setIsOverflowing(overflowing);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return { isOverflowing };
};
