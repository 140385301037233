import React, { useCallback, useEffect } from "react";

import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import ChipFilters from "components/rhombus-UI/ChipFilters";
import { navigate } from "gatsby";
import IconSearch from "components/common/icon-components/IconSearch";
import { styled } from "linaria/react";
import FadeIn from "components/common/animated-sections/FadeIn";

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  @media (max-width: 820px) {
    width: 100%;
    flex-direction: column;
  }
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--blue-500);
  background: transparent;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: all 250ms;
  :hover {
    background: #eff6fe;
  }
  @media (max-width: 820px) {
    width: 100%;
    text-align: center;
    justify-content: center;
    :hover {
      background: transparent;
    }
  }
`;

const HeaderText = styled(TextContainer)`
  gap: 1.25rem;
  text-align: center;
  height: 140px;
  @media (max-width: 600px) {
    h1 {
      font-size: 32px;
    }
  }
`;

export default function BlogHeader({
  headerText,
  categoryGroups,
  selectedCategory,
  setSelectedCategory,
  category,
  setMenuOpen,
}) {
  useEffect(() => {
    if (selectedCategory === "All Categories") {
      navigate("/blog/");
    }
    const slug = categoryGroups.find(item => {
      return item.category === selectedCategory;
    })?.slug;

    navigate(slug);
  }, [selectedCategory]);

  const handleTabSelection = useCallback(filterArray => {
    setSelectedCategory(filterArray[0]);
  }, []);

  return (
    <SectionContainer>
      <SectionInner
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FadeIn>
          <HeaderText width="798px">
            <FancyPageTitle>{headerText.title}</FancyPageTitle>
            <MainParagraph>{headerText.paragraph}</MainParagraph>
          </HeaderText>
        </FadeIn>

        <FilterContainer>
          <ChipFilters
            fallBackSelection={"All Categories"}
            defaultSelected={category}
            options={categoryGroups.map(({ category }) => category)}
            handleFilter={handleTabSelection}
            center
          />
          <SearchButton onClick={() => setMenuOpen(true)}>
            <IconSearch />
            Search
          </SearchButton>
        </FilterContainer>
      </SectionInner>
    </SectionContainer>
  );
}
