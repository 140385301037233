/**
 * Returns the display name of a category
 *
 * @example ```
 *  getCategoryName("Industry Solutions/Education")
 *  // => Education
 *  getCategoryName("New Features")
 *  // => New Features
 * ```
 */
export function getCategoryName(fullCategory) {
  if (!fullCategory || !fullCategory.split) {
    return null;
  }
  const [category, subCategory] = fullCategory.split("-");
  return category;
}

export default { getCategoryName };
