import React, { useState } from "react";
import { css, cx } from "linaria";
import BlogPostCard from "./BlogPostCard";
import { get } from "lodash";
import { menuOpenAtom } from "./state";
import { useAtom } from "jotai";
import CloseIcon from "components/common/icon-components/Close";
import { CloseButton } from "components/rhombus-UI/theme/ui-elements";
import {
  SearchBar,
  SearchBarWrapper,
} from "components/integrations/marketplace/styles/MarketPlace-css";
import {
  DefaultFlexContainer,
  SectionContainer,
} from "components/rhombus-UI/theme/containers";
import IconSearch from "components/common/icon-components/IconSearch";
import { MainParagraph } from "components/rhombus-UI/theme/typography";
import IconNotFound from "components/common/icon-components/IconNotFound";

const filterClass = css`
  opacity: 0;
`;

const filterClassOpen = cx(
  filterClass,
  css`
    animation: fadein 2.2s;
    @keyframes fadein {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    opacity: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
  `
);

const buttonContainerClass = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 25px;
  position: sticky;
  top: 0;
`;

const filterContentClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
`;

const postsContainer = css`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 2.5rem 0;

  > div {
    width: 100%;
    > div {
      max-width: 495px;
    }
  }
`;

export default function BlogSearch({ posts, featuredPosts }) {
  const [searchFilter, setSearchFilter] = useState("");
  const [menuOpen, setMenuOpen] = useAtom(menuOpenAtom);

  const filteredPosts = posts.filter(post => {
    if (!searchFilter) return true;

    const titleMatch = get(post.node, "frontmatter.title", "")
      .toLowerCase()
      .includes(searchFilter.toLowerCase());
    const contentMatch = get(post.node, "html", "")
      .toLowerCase()
      .includes(searchFilter.toLowerCase());

    return titleMatch || contentMatch;
  });

  const updateSearchFilter = nextSearchFilter => {
    setSearchFilter(nextSearchFilter);
  };

  return (
    <div>
      <div className={menuOpen ? filterClassOpen : filterClass}>
        <div className={buttonContainerClass}>
          <CloseButton
            onClick={() => {
              setMenuOpen(false);
              updateSearchFilter("");
            }}
          >
            <CloseIcon />
          </CloseButton>
        </div>
        <SectionContainer>
          <div className={filterContentClass}>
            <SearchBarWrapper
              style={{
                maxWidth: "495px",
              }}
            >
              <IconSearch color="var(--blue-500)" />
              <SearchBar
                type="search"
                placeholder="Search"
                onChange={ev => updateSearchFilter(ev.target.value)}
                value={searchFilter}
              />
            </SearchBarWrapper>
            {searchFilter === "" ? (
              <div className={postsContainer}>
                {featuredPosts.map(post => (
                  <BlogPostCard key={post.node.id} post={post.node} />
                ))}
              </div>
            ) : filteredPosts.length ? (
              <div className={postsContainer}>
                {filteredPosts.map(post => (
                  <BlogPostCard key={post.node.id} post={post.node} />
                ))}
              </div>
            ) : (
              <></>
            )}
            {searchFilter !== "" && !filteredPosts.length && (
              <DefaultFlexContainer
                style={{
                  flexDirection: "column",
                  marginTop: "2.5rem",
                  width: "100%",
                }}
              >
                <IconNotFound
                  style={{
                    width: "495px",
                    maxHeight: " 200px",
                    maxWidth: "100%",
                  }}
                />
                <MainParagraph
                  style={{ fontSize: "24px" }}
                  color="var(--blue-800)"
                >
                  No Blogs Found
                </MainParagraph>
              </DefaultFlexContainer>
            )}
          </div>
        </SectionContainer>
      </div>
    </div>
  );
}
