import { css } from "linaria";
import PropTypes from "prop-types";
import React from "react";

import Link from "./Link";

const linkClass = css`
  font-size: 16px;
  text-decoration: none;
  color: var(--blue-500);
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

/**
 * @deprecated
 */

export default function TextLink({ className, ...rest }) {
  const rootClass = [linkClass, className].filter(x => x).join(" ");
  return <Link {...rest} className={rootClass} />;
}

TextLink.propTypes = {
  className: PropTypes.string,
};

TextLink.defaultProps = {
  className: null,
};
