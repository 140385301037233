import React from "react";
import { css } from "linaria";
import BlogPostCard from "./BlogPostCard";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import useIsMobile from "hooks/useIsMobile";
import {
  SectionCol,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { TitleMed } from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";

const featuredContainer = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2.5rem;
  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SectionLeft = styled(SectionCol)`
  @media (max-width: 1050px) {
    width: 100%;
  }
`;
const SectionRight = styled(SectionCol)`
  justify-content: flex-end;
  width: unset;
  @media (max-width: 1050px) {
    width: 100%;
    justify-content: center;
  }
`;

export default function BlogFeaturedPosts({ featuredPosts, category }) {
  const { isMobile } = useIsMobile(1050);
  return (
    <section style={{ width: "100%" }}>
      <div className={featuredContainer}>
        <SectionLeft>
          <TextContainer style={{ gap: "1.25rem" }}>
            <TitleMed>
              {category === "all"
                ? "Editor’s Picks"
                : `Featured in ${category}`}
            </TitleMed>
            <BlogPostCard
              key="featured-blog-post"
              post={featuredPosts[0].node}
              featured
            />
          </TextContainer>
        </SectionLeft>
        <SectionRight>
          <FormWrapper
            outline
            title="Subscribe to our blog!"
            width={isMobile ? "100%" : "550px"}
          >
            <RhombusForm formId="2623572b-bd56-4779-980e-767bb2abbbd9" />
          </FormWrapper>
        </SectionRight>
      </div>
    </section>
  );
}
